import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-multiselect-autocomplete',
  templateUrl: './multiselect-autocomplete.component.html',
  styleUrls: ['./multiselect-autocomplete.component.scss']
})
export class MultiselectAutocompleteComponent implements OnChanges {

  rawData: any[] = [];
  filteredData: any[] = [];
  selectData: any[] = [];
  isSelectAllChecked: boolean = false;

  @Output() outcome: EventEmitter<any> = new EventEmitter<any>();

  @Input() placeholder: string = 'Select Data';
  @Input() multiselect: boolean = true;
  @Input() language: string;
  @Input() control: FormControl<any[]>;
  @Input() addSelectAll: boolean = false;
  @Input() set data(data: any[]) {
    if (data.length) {
      data?.forEach((item) => {
        this.rawData.push({ ...item, selected: false });
      });
      this.filteredData = this.filterData();
    }
    if (this.control) this.populateControlInput();
  };
  @Input() selectedFromOutsideKey: string = 'id';
  @Input() filterKey: string = '';

  @Input() set selectedItems(data: any[]) {
    this.filteredData.forEach(item => item.selected = false);
    this.selectData = data?.length ? data : [];
    if (this.selectData.length) {
      this.selectData?.forEach((item: any) => {
        const i = this.filteredData.find(c => item.id === c.id);
        i.selected = true;
      });
    }
  }

  constructor() {}

  populateControlInput(): void {
    if (this.control.value?.length) {
      this.control.value.forEach((item: any) => {
        const itemInFiltered = this.rawData
          .find((rawDataItem) => rawDataItem[this.selectedFromOutsideKey] === item[this.selectedFromOutsideKey]);
        if (itemInFiltered) {
          itemInFiltered.selected = true;
          this.selectData.push(itemInFiltered);
        }
      });
    }
  }

  ngOnChanges({control}: SimpleChanges): void {
    if (control) this.populateControlInput();
  }

  onInputChange(event: Event): void {
    this.filteredData = this.filterData(event.target as HTMLInputElement);
    if (this.addSelectAll) {
      this.isSelectAllChecked = this.filteredData.every(item => {
        return item.selected;
      });
    }
  }

  filterData(event?: HTMLInputElement): any[] {
    if (event?.value) {
      const filterValue = event.value.toLowerCase();
      return this.rawData.filter((item) => {
        const value = this.language ? item[this.filterKey][this.language] : item[this.filterKey];
        return value.toLowerCase().indexOf(filterValue) >= 0;
      });
    } else {
      return this.rawData.slice();
    }
  }
  ​
  optionClicked(event: Event, data: any): void {
    event.stopPropagation();
    this.toggleSelection(data);
  };

  toggleSelection(data: any, setFiltered?: boolean): void {
    // if we want to use multiselect as regular select/single

    if (!this.multiselect && this.selectData.length) {
      if (!data.selected) {
        this.selectData[0].selected = !this.selectData[0].selected;
      }
      this.selectData = [];
    }

    data.selected = !data.selected;

    if (setFiltered) {
      const findInFiltered = this.filteredData.find(item => item.id === data.id);
      findInFiltered.selected = data.selected;
    }

    if (data.selected) {
      this.selectData.push(data);
    } else {
      const i = this.selectData.findIndex(value => value.id === data.id);
      if (i > -1) {
        this.selectData.splice(i, 1);
      }
    }

    if (this.control) {
      this.control.setValue(this.selectData);
      return;
    }
    this.outcome.emit({
      allSelectedData: this.selectData,
      selectedData: data,
    });

    if (this.addSelectAll) {
      this.isSelectAllChecked = this.isSelectAllSelected(data);
    }
  };

  isSelectAllSelected(data: any): boolean {
    if (!data.selected) {
      return false;
    } else {
      if (this.filteredData.every(item => item.selected)) {
        return true;
      }
    }
    return false;
  }

  selectAll(): void {
    this.isSelectAllChecked = !this.isSelectAllChecked;
    this.filteredData.forEach((item: any) => {
      if (this.isSelectAllChecked && !item.selected) {
        item.selected = !item.selected;
        this.selectData.push(item);
        this.outcome.emit({
          allSelectedData: this.selectData,
          selectedData: item
        });
      }

      if (!this.isSelectAllChecked && item.selected) {
        item.selected = !item.selected;
        const i = this.selectData.findIndex(value => value.id === item.id);
        if (i > -1) {
          this.selectData.splice(i, 1);
          this.outcome.emit({
            allSelectedData: this.selectData,
            selectedData: item
          });
        }
      }
    });
  }

  onSelectAll(event: Event): void {
    event.stopPropagation();
    this.selectAll();
  }
}
